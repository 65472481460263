import React, { useState } from 'react'
import Link from 'gatsby-link'
import Hero from '../components/loans/Hero'
import Welcome from "../components/loans/Welcome"
import Content from "../components/loans/Content"
import Security from "../components/loans/LoanSecurity"
import FormLink from "../components/loans/FormLink"
import RightContent from "../components/loans/RightContent"
import StatsForm from "../components/stats/StatsForm"
import COLORS from "../resources/colors"
import Layout from "../components/layout"
import Image2 from "../images/analytics-2.png"
import Image3 from "../images/document.png"
import Image1 from "../images/coin.png"
import Image4 from "../images/blueprint-2.png"
import { navigate } from "gatsby"
import Logo from "../images/light-purple-blue-logo.svg"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default function TestPage() {

  const [formDisplayed, setFormDisplayed] = useState(false);

  let paymentText = <span><span style={{ fontWeight: "800" }}>Empower your customers to pay and settle their loans with any currency.</span> Our powerful AI engine also recommends smart payment arrangements that actually work for your consumers, helping them adjust their payments and get back on track if they fall behind.</span>
  let innovationText = <span><span style={{ fontWeight: "800" }}>Drive more payments and improve your bottom line</span> with our AI-driven repayment and collection strategies. Our features support a diverse range of loan programs, ensuring you stay at the leading edge of financial innovation. Plus, with our marketplace, you're guaranteed to increase your reach and engage with high quality leads all within an industry-leading customer experience.</span>
  let reportingText = <span><span style={{ fontWeight: "800" }}>The highest levels of security and compliance standards.</span> Our team works around the clock with regulators across the nation to ensure our platform adapts to an ever-changing regulatory environment so you don't have to. Our RESTful API gives you the real-time data you need to review and act on your loan portfolio. Now, you can make important decisions confidently and faster than ever before.</span>
  let integrationText = <span><span style={{ fontWeight: "800" }}>For developers, by developers.</span> With just a few lines of code, you can implement Clerkie in one afternoon. Our simple RESTful API allows you to get started in just a few steps. For more complex integrations, we do all the heavy lifting, taking the pain out of deployment. Plus, our cross-platform solution increases your reach and puts your customers in control, giving them the flexibility to pay whenever, wherever online or on mobile.Your innovations and ideas are always welcome. Get in touch!</span>

  function displayForm() {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Learn More"
    })
    setFormDisplayed(true)
  }

  function hideForm() {
    setFormDisplayed(false)
  }


  return (
    <Layout primaryLogo={Logo} primaryColor={COLORS.LightPurpleBlue} headerScrolledColor={"black"} secondaryButtonPrimaryBackground={"#333333"} secondaryButtonPrimaryColor={"white"} heroMobileMultiplier={0.4585} heroDesktopMultiplier={1.85} buttons={[
      {
        title: "Learn More",
        action: displayForm
      },
      {
        title: "Request Demo",
        action: displayForm,
        showOnMobile: true,
        isSecondary: true
      },
      {
        title: "Lenders",
        action: function () {
          navigate("/lenders")
          trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Button",
            // string - required - Type of interaction (e.g. 'play')
            action: "Click",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: "Lenders"
          })
        },
        selected: true
      },
      {
        title: "Companies",
        action: function () {
          navigate("/companies")
          trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Button",
            // string - required - Type of interaction (e.g. 'play')
            action: "Click",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: "Companie"
          })
        }
      },
      {
        title: "Members",
        action: function () {
          navigate("/")
          trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Button",
            // string - required - Type of interaction (e.g. 'play')
            action: "Click",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: "Members"
          })
        },
        showOnMobile: false
      }

    ]} menuButtons={
      [
        {
          title: "Members",
          type: "item",
          action: function () {
            navigate("/")
            trackCustomEvent({
              // string - required - The object that was interacted with (e.g.video)
              category: "Button",
              // string - required - Type of interaction (e.g. 'play')
              action: "Click",
              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
              label: "Members"
            })
          }
        },
        {
          title: "Companies",
          type: "item",
          action: function () {
            navigate("/companies")
            trackCustomEvent({
              // string - required - The object that was interacted with (e.g.video)
              category: "Button",
              // string - required - Type of interaction (e.g. 'play')
              action: "Click",
              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
              label: "Companies"
            })
          }
        },
        {
          title: "Lenders",
          type: "item",
          action: function () {
            navigate("/lenders")
            trackCustomEvent({
              // string - required - The object that was interacted with (e.g.video)
              category: "Button",
              // string - required - Type of interaction (e.g. 'play')
              action: "Click",
              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
              label: "Lenders"
            })
          },
          selected: true
        }
      ]
    }>
      <div className="LendersRoot">

        <div className={formDisplayed ? "FormBackground Visible" : "FormBackground Hidden"}>
          <StatsForm title="Request a demo and try it out. It's very easy!" name="lenders-form" hideForm={hideForm} />
        </div>


        <div className={formDisplayed ? "LendersContent Disabled" : "LendersContent Active"}>
          <Hero displayForm={displayForm}></Hero>
          <Welcome></Welcome>
          <Content imageMultiplier={0.85} id="loanpayment" src={Image1} backgroundColor={COLORS.black} title="Smart Payment Experience" paragraph={paymentText}></Content>
          <RightContent imageMultiplier={1.0} id="loaninnovations" src={Image2} backgroundColor={COLORS.black} title="3x Revenue Performance" paragraph={innovationText}></RightContent>
          <Content id="loanreporting" src={Image3} backgroundColor={COLORS.black} title="Real-time Reporting and Compliance" paragraph={reportingText}></Content>
          <RightContent id="loanintegration" src={Image4} backgroundColor={COLORS.black} title="Fast and Easy Integration" paragraph={integrationText}></RightContent>
          <Security></Security>
          <FormLink displayForm={displayForm}></FormLink>
        </div>


        <style global jsx="true">{`

                body { 
                    font-family: "Helvetica Neue", "Arial";
                    margin: 0px;
                    padding: 0px;
                    overflow: ${formDisplayed ? "hidden" : "auto"};
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background-color: black;
                } 
      `}</style>


        <style jsx="true">{`
          
            .LendersRoot { 
              display: grid;
              align-items: start;
              overflow: hidden;
            }

            .LendersContent { 
              grid-row: 1;
              grid-column: 1;
              overflow: hidden;
            }

            

            .LendersContent.Active { 
              pointer-events: auto;
            }

            .LendersContent.Disabled { 
              pointer-events: none;
            }

            .FormBackground { 
              grid-row: 1;
              grid-column: 1;
              height: 100%;
              width: 100%;
              z-index: 100;
              transition: 0.15s; 
              opacity: 0;
            }

            .FormBackground.Hidden { 
              opacity: 0;
              pointer-events: none;
            }

            .FormBackground.Visible { 
              opacity: 100;
              pointer-events: auto;
            }

        `}</style>
      </div>
    </Layout>
  )
} 
